<template>
<div>
  <bar title="true" closeActivation="true"/>

  <v-content>
    <message/>
<v-container>
	<v-form ref="form">
		<v-layout align-center justify-center row fill-height>

	      <v-flex offset-md2 md6 offset-sm2 sm6 xs7 ml-3>
          <v-text-field label="Código de activación" :rules="[validateRequired, validatePassword]" type="hash" v-model="hash"></v-text-field>
	      </v-flex>

        <v-flex md2 sm2 xs5 mr-3>
          <v-btn @click="action" :loading="loading" fab dark large color="primary">
            <v-icon dark>mdi-send</v-icon>
          </v-btn>
        </v-flex>

		</v-layout>
	</v-form>
</v-container>
  </v-content>

  <pl-footer/>
</div>

</template>

<script>

import bar from '../components/appComponents/Bar.vue'
import message from '../components/appComponents/Message.vue'
import footer from '../components/appComponents/Footer.vue'

export default {
  name: 'CheckRequest',
  data() {
    return {
      hash: '',
      loading: false
    }
  },
  components: {
    'pl-footer': footer,
    message,
    bar
  },
  mounted(){
    this.$root.$emit('changeMessage', { show : true , text : 'A continuación recibirás un código de activación en tu email, introdúcelo en el formulario y pulsa enviar para continuar con el proceso' } )
  },
  methods: {
    action: function () {
      let valid = this.$refs.form.validate();
      if (valid && !this.loading) {
        this.loading = true

        let params = { 
              hash: this.hash
        }
        this.$store.commit('recalculateCaptcha')
        let config = { headers: {
              Accept: 'application/json',
              'Captcha-Authorization': this.$store.state.captchaTokenResult
        }}

        if (this.$route.params.type === 'delete'){
          config.headers['Authorization'] = 'Bearer '+this.$store.state.accessToken
        }

        this.$http.post(this.$store.state.urlApi+'auth/'+this.$route.params.type+'/request/check', params,config).then(function() {
          this.$store.state.currentRoute = '/'+this.$route.params.type+'/'+this.hash
          this.$router.push(this.$store.state.currentRoute)
        }, function() {
          this.$root.$emit('changeSnackbar', { show : true , text : 'Tu código de activación no es correcto, introdúcelo de nuevo', timeout: 4000, type: 'warning' } )
          this.loading = false
        });
      } 
    },
    validateRequired: function(value){
      return !!value || 'obligatorio'
    },
    validatePassword: function(value){
      return (value.length === 6) || 'Introduce 6 caracteres'
    }
  }
}
</script>

<style>

</style>
